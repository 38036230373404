import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Container } from "@material-ui/core";

const Success = () => {
return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
    <Typography variant="h4" gutterBottom>
        Thank you for submitting the form!
    </Typography>
    <Typography variant="body1" gutterBottom>
        Our team will contact you within 24 hours.
    </Typography>
    <Button
        variant="contained"
        component={Link}
        to="/"
        style={{
            background: "#00A36C",
            fontWeight: "bold",
            marginTop: "16px",
            color: "#FFFFFF",
            }}
    >
        Back to Home
    </Button>
    </Container>
);
};

export default Success;
