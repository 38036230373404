import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ToastContainer } from "react-toastify";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";

const HeroSecDiv = styled.div`
height: 25vh;
background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(/img/customize/ho.jpg);
background-position: center;
background-size: cover;

display: grid;
place-content: center;
color: white;
text-align: center;

h2 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: capitalize;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
`;

const ShowItemsContainer = styled.div`
padding: 50px 0;

.p-container {
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: 30px;
    padding: 100px 0;

    p {
    color: #404040;
    font-size: 1.26rem;
    font-weight: 500;
    line-height: 160%;
    }
}

`;

const TitleBar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 50px;

p {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
    color: #606060;
    font-size: 1.2rem;
    background: #f0f0f0;
    width: 100%;
    padding: 10px 16px;
}
@media screen and (max-width: 1199px) {
    margin-bottom: 10px;
}
select {
    border: 1px solid #808080;
    padding: 10px 16px;
}

.search-cont {
    position: relative;
    width: fit-content;

    input {
    border: 1px solid #808080;
    padding: 10px 20px;
    }

    svg {
    position: absolute;
    top: 10px;
    right: 6px;
    }

    @media screen and (max-width: 1199px) {
    display: none;
    }
}
`;

export default function Transportation() {

return (
    <>
    <NavBar />
    <HeroSec />
    <>
    <ShowItemsContainer>
        <Container maxWidth="lg">
            <TitleBar>
                <p>Delivery & Installation</p>
            </TitleBar>
            <div style={{ textAlign: "center" }}>
                <img
                    src="/img/services/delivery.jpg" // Adjust the image path as necessary
                    alt="Transportation and Delivery"
                    style={{ maxWidth: "70%", height: "auto" }}
                />
            </div>
            <div className="p-container">
                <p>
                We at INDOWATT have cultivated strong partnerships with numerous reputable logistics companies across Indonesia. This expansive network allows us to seamlessly navigate logistics challenges and efficiently deliver our products to customer sites, irrespective of the geographical location. Whether it's bustling metropolitan areas like Jakarta, resource-rich regions like Kalimantan and Sulawesi, or industrial hubs like Surabaya and Batam, our logistical reach ensures prompt and reliable delivery to our valued customers.
                </p>

                <p>
                Furthermore, our commitment extends beyond efficient logistics. We prioritize the safety and security of our products throughout the delivery process. With comprehensive insurance coverage for all shipments, our customers benefit from added protection from the moment products leave our facilities until they reach your doorstep. This meticulous attention to detail reflects our dedication to ensuring a worry-free delivery process, ensuring that your INDOWATT experience is not just about superior products but also about a seamless and secure delivery, right to your desired sites.
                </p>
            </div>
        </Container>
    </ShowItemsContainer>
    </>
    {/* CONTACT SECTION */}
    <Box sx={{ mt: 10 }}>
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a", textAlign: "center" }}
        fontSize={30}
        fontWeight={700}
        id="contact">
        GET IN TOUCH
        </Typography>

        <Box sx={{}}>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}>
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </Box>

    <div style={{ backgroundColor: "#EEEFF1" }}>
        <Container>
        <Footer />
        </Container>
    </div>

    <a
        href="https://wa.me/6281111127798"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </a>
    </>
);
}

function HeroSec() {
return (
    <HeroSecDiv>
    <div>
        <h2>Delivery & Installation</h2>
        <p>
        <span>Services</span>
        <span>{">"}</span>
        <span>Delivery & Installation</span>
        </p>
    </div>
    </HeroSecDiv>
);
}
