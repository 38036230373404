import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ToastContainer } from "react-toastify";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import { useState } from "react";
import CustomAccordion from "./accordian";

const HeroSecDiv = styled.div`
height: 25vh;
background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(/img/customize/ho.jpg);
background-position: center;
background-size: cover;

display: grid;
place-content: center;
color: white;
text-align: center;

h2 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: capitalize;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
`;

const ShowItemsContainer = styled.div`
padding: 50px 0;
`;

const ItemsContainer = styled.div`
display: flex;
justify-content: space-between;
gap: 50px;
@media screen and (max-width: 599px) {
    display: block;
}
.cardCont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    @media screen and (max-width: 899px) {
    grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 599px) {
    grid-template-columns: repeat(1, 1fr);
    }
}

.icard {
    border: 1px solid #d7d7d7;
    padding: 0px 0px;

    display: grid;
    grid-template-columns: repeat(1fr);

    p {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    text-decoration: none;
    color: #404040;
    font-weight: 600;
    padding: 15px 8px 15px 8px;
    }

    .img-container {
    position: relative;
    height: 240px;
    overflow: hidden;
    @media screen and (max-width: 899px) {
        height: 300px;
    }
    @media screen and (max-width: 599px) {
        width: 100%;
        height: auto;
    }
    img {
        height: 100%;
        transform: scale(1);
        transition: 0.3s;
        object-fit: cover;
        width: 100%;
        &:hover {
        transform: scale(1.1);
        }
    }
    }

    &:hover .abs-card {
    transform: translate(-50%, -50%) rotateX(360deg) scale(1);
    opacity: 1;
    transition: 750ms ease-in-out;
    }
    &:hover .abs-srch-icon {
    opacity: 1;
    }
}

img {
    width: 100%;
}
`;

const Right = styled.div`
display: flex;
flex-direction: column;
gap: 0px;

select {
    padding: 16px;
    border: 1px solid #606060;
    border-radius: 0.2rem;

    color: #606060;
}

h2 {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
    color: #000048;
    font-size: 2rem;
}

@media screen and (max-width: 1199px) {
    display: none;
}
`;
const MobileAccordian = styled.div`
/* display: flex; */
gap: 0px;
padding-bottom: 20px;

select {
    padding: 16px;
    border: 1px solid #606060;
    border-radius: 0.2rem;

    color: #606060;
}

h2 {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
    color: #000048;
    font-size: 2rem;
}
.accordianStyle {
    height: fit-content;
    width: 100%;
}
.css-10p4bf5-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0px 0;
}
@media screen and (min-width: 1199px) {
    display: none;
}
`;

const TitleBar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 50px;

p {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
    color: #606060;
    font-size: 1.2rem;
    background: #f0f0f0;
    width: 100%;
    padding: 10px 16px;
}
@media screen and (max-width: 1199px) {
    margin-bottom: 10px;
}
select {
    border: 1px solid #808080;
    padding: 10px 16px;
}

.search-cont {
    position: relative;
    width: fit-content;

    input {
    border: 1px solid #808080;
    padding: 10px 20px;
    }

    svg {
    position: absolute;
    top: 10px;
    right: 6px;
    }

    @media screen and (max-width: 1199px) {
    display: none;
    }
}
`;

export default function Customize({ data }) {
console.log(data);

return (
    <>
    <NavBar />
    <HeroSec />
    <ShowItems data={data} />
    {/* CONTACT SECTION */}
    <Box sx={{ mt: 10 }}>
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a", textAlign: "center" }}
        fontSize={30}
        fontWeight={700}
        id="contact">
        GET IN TOUCH
        </Typography>

        <Box sx={{}}>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}>
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </Box>

    <div style={{ backgroundColor: "#EEEFF1" }}>
        <Container>
        <Footer />
        </Container>
    </div>

    <a
        href="https://wa.me/6281111127798"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </a>
    </>
);
}

function ShowItems({ data }) {
const [dtArr, setDtArr] = useState([
    ...data.opentypedieselgenerator.cummins,
    ...data.opentypedieselgenerator.perkins,
    // ...data.silenttypedieselgenerator.cummins,
    // ...data.silenttypedieselgenerator.perkins,
    // ...data.containertypedieselgenerator.cummins,
    // ...data.containertypedieselgenerator.perkins,
]);

function updateDtArr(arr, type, init, allFalse, allFalseObj) {
    if (arr.length > 0) {
    if (type === "remove") {
        console.log(allFalse);
        if (allFalse) {
        setDtArr(Object.values(allFalseObj).flat());
        } else {
        setDtArr(
            dtArr.filter(
            (item) => !arr.some(({ title }) => title === item.title)
            )
        );
        }
    } else {
        if (init) {
        setDtArr(arr);
        } else {
        setDtArr([...dtArr, ...arr]);
        }
    }
    } else {
    if (type == null) {
        setDtArr(arr);
    } else {
        let nArr = [];
        Object.keys(data[type]).forEach((eObj) => {
        nArr.push(...data[type][eObj]);
        });

        setDtArr(nArr);
    }
    }
}
console.log(dtArr);

const [currentPage, setCurrentPage] = useState(1);
const itemsPerPage = 9;
const totalPages = Math.ceil(dtArr.length / itemsPerPage);

const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
};
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const pageProducts = dtArr.slice(startIndex, endIndex);
return (
    <>
    <ShowItemsContainer>
        <Container maxWidth="lg">
        <TitleBar>
            <p>Diesel Generator Set</p>
        </TitleBar>
        <MobileAccordian>
            <CustomAccordion
            data={data}
            mobile={true}
            updateDtArr={updateDtArr}
            setDtArr={setDtArr}
            setCurrentPage={setCurrentPage}
            />
        </MobileAccordian>

        <ItemsContainer>
            <div className="cardCont">
            {dtArr.length > 0 &&
                pageProducts.map((obj, i) => (
                <div className="icard" key={i}>
                    <div className="img-container">
                    <img src={obj.img} alt="card-img" />
                    </div>

                    <p className="title">{obj.title}</p>
                </div>
                ))}

            {dtArr.length === 0 && (
                <div>
                <p style={{ color: "#606060", fontWeight: "500" }}>
                    Choose a filter
                </p>
                </div>
            )}
            </div>

            <Right>
            <CustomAccordion
                data={data}
                updateDtArr={updateDtArr}
                setDtArr={setDtArr}
                setCurrentPage={setCurrentPage}
            />
            </Right>
        </ItemsContainer>
        </Container>
    </ShowItemsContainer>
    {/* <Box
        sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        }}>
    <p>Hello</p>
    </Box> */}
    {totalPages > 1 && (
        <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        />
    )}
    </>
);
}
export const Pagination = ({ totalPages, currentPage, onPageChange }) => {
const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
);
console.log("pageNumbers", pageNumbers, totalPages);
return (
    <div className="pagination-sec">
    <Button
        sx={{
            color: (theme) => (currentPage === 1 ? theme.palette.text.disabled : '#00A36C'),
            borderColor: (theme) => (currentPage === 1 ? theme.palette.action.disabled : '#00A36C'),
            '&:hover': {
                color: (theme) => (currentPage === 1 ? theme.palette.primary.main : '#00A36C'),
                borderColor: (theme) => (currentPage === 1 ? theme.palette.primary.main : '#00A36C'),
            },
        }}
        size="small"
        variant="outlined"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}>
        Prev
    </Button>
    <ul className="pagination-ul">
        {pageNumbers.map((page) => (
        <li
            key={page}
            onClick={() => onPageChange(page)}
            className={`${page === currentPage ? "activeClass" : ""}`}
            style={{ cursor: "pointer" }}>
            {page}
        </li>
        ))}
    </ul>
    <Button
        sx={{
            color: (theme) => (currentPage === totalPages ? theme.palette.text.disabled : '#00A36C'),
            borderColor: (theme) => (currentPage === totalPages ? theme.palette.action.disabled : '#00A36C'),
            '&:hover': {
                color: (theme) => (currentPage === totalPages ? theme.palette.primary.main : '#00A36C'),
                borderColor: (theme) => (currentPage === totalPages ? theme.palette.primary.main : '#00A36C'),
            },
        }}
        size="small"
        variant="outlined"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}>
        Next
    </Button>
    </div>
);
};

function HeroSec() {
return (
    <HeroSecDiv>
    <div>
        <h2>Diesel Generators</h2>
        <p>
        <span>Products</span>
        <span>{">"}</span>
        <span>Diesel Generators</span>
        </p>
    </div>
    </HeroSecDiv>
);
}
