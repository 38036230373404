import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Link as Rlink } from "react-router-dom";

import styled from "@emotion/styled";

import { useLocation } from "react-router-dom";
// import brochurePDF from "./IWS_ENG_BROCHURE.pdf";

const pages = [
{ text: "ABOUT US", href: "#about" },
{ text: "PRODUCTS", href: "#products" },
// { text: "PRODUCTS", href: "#products", brochure: brochurePDF },
{ text: "SERVICES", href: "#services" },
{ text: "CONTACT US", href: "#contact" },
// { text: 'BLOG', href: 'https://www.google.com.sg/', target: "_blank" },
];

const DropDownBox = styled.div`
position: relative;
&:hover div {
    visibility: visible;
}
`;

const DropDown = styled.div`
position: absolute;
background: #fff;
padding: 10px;
width: 215px;
top: 0;
left: 0;
transform: translate(0%, 54px);
visibility: hidden;
display: flex;
flex-direction: column;
a {
    padding: 10px;
    color: #07bc0c;
    &:hover {
    color: white;
    background-color: #07bc0c;
    }
}
`;

function NavBar({ handleNavClick }) {
const location = useLocation();
const currentPath = location.pathname;

const [anchorElNav, setAnchorElNav] = React.useState(null);
// const [anchorElUser, setAnchorElUser] = React.useState(null);

const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
};
// const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
// };

const handleCloseNavMenu = () => {
    setAnchorElNav(null);
};

// const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
// };

return (
    <AppBar
    position="static"
    sx={{
        background: "transparent",
        mt: 0.5,
        diplay: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    }}
    >
    <Container
        maxWidth="xl"
        sx={{
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        }}
    >
        <Toolbar disableGutters sx={{ padding: "0 16px" }}>
        <Rlink to={"/"}>
            <Box
            component="img"
            sx={{
                height: 32,
                width: 110,
                display: { xs: "none", md: "flex" },
                mr: 1,
            }}
            alt="INDOWATT"
            src="../indowatt-only.svg"
            />
        </Rlink>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            >
            <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
                display: { xs: "flex", md: "none" },
            }}
            >
            {pages.map((page, i) => (
                <MenuItem
                key={i}
                onClick={handleCloseNavMenu}
                style={{ background: "black" }}
                >
                {page.text === "PRODUCTS" ? (
                    <div>
                    <Rlink
                        onClick={(e) => {
                        if (currentPath === "/") {
                            handleNavClick(e.target.href);
                        }
                        }}
                        to={currentPath === "/" ? page.href : `/${page.href}`}
                        style={{
                        color: "white",
                        display: "flex",
                        textDecoration: "none",
                        fontWeight: 500,
                        }}
                    >
                        {page.text}
                        <span
                        style={{
                            transform: "rotate(90deg) scaleY(1.5)",
                            marginLeft: "8px",
                        }}
                        >
                        {">"}
                        </span>
                    </Rlink>

                    <div
                        style={{
                        color: "white",
                        marginLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        }}
                    >
                        <Rlink
                        to={`/diesel-generator`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Diesel Generator
                        </Rlink>
                        <Rlink
                        to={`/alternators`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Alternators
                        </Rlink>
                    </div>
                    </div>
                ) : page.text === "SERVICES" ? (
                    <div>
                    <Rlink
                        onClick={(e) => {
                        if (currentPath === "/") {
                            handleNavClick(e.target.href);
                        }
                        }}
                        to={currentPath === "/" ? page.href : `/${page.href}`}
                        style={{
                        color: "white",
                        display: "flex",
                        textDecoration: "none",
                        fontWeight: 500,
                        }}
                    >
                        {page.text}
                        <span
                        style={{
                            transform: "rotate(90deg) scaleY(1.5)",
                            marginLeft: "8px",
                        }}
                        >
                        {">"}
                        </span>
                    </Rlink>

                    <div
                        style={{
                        color: "white",
                        marginLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        }}
                    >
                        <Rlink
                        to={`/products-consultation`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Products Consultation
                        </Rlink>
                        <Rlink
                        to={`/delivery-and-installation`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Delivery & Installation
                        </Rlink>
                        <Rlink
                        to={`/after-sales`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        After Sales Services
                        </Rlink>
                    </div>
                    </div>
                ) : (
                    <Rlink
                    onClick={(e) => {
                        if (currentPath === "/") {
                        handleNavClick(e.target.href);
                        }
                    }}
                    to={currentPath === "/" ? page.href : `/${page.href}`}
                    style={{
                        color: "white",
                        display: "flex",
                        textDecoration: "none",
                        fontWeight: 500,
                    }}
                    >
                    {page.text}
                    </Rlink>
                )}
                </MenuItem>
            ))}
            </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, i) => (
            <DropDownBox key={i}>
                <Rlink
                onClick={(e) => {
                    if (currentPath === "/") {
                    handleNavClick(e.target.href);
                    }
                }}
                to={currentPath === "/" ? page.href : `/${page.href}`}
                target={page?.target}
                style={{
                    margin: "16px",
                    color: "black",
                    display: "flex",
                    textDecoration: "none",
                    fontWeight: 500,
                }}
                >
                {page.text}
                {page.text === "PRODUCTS" && (
                    <span
                    style={{
                        transform: "rotate(90deg) scaleY(1.5)",
                        marginLeft: "8px",
                    }}
                    >
                    {">"}
                    </span>
                )}

                {page.text === "SERVICES" && (
                    <span
                    style={{
                        transform: "rotate(90deg) scaleY(1.5)",
                        marginLeft: "8px",
                    }}
                    >
                    {">"}
                    </span>
                )}
                </Rlink>
                {page.text === "PRODUCTS" && (
                <DropDown>
                    <Rlink
                    to={`/diesel-generator`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Diesel Generator
                    </Rlink>
                    <Rlink
                    to={`/alternators`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Alternators
                    </Rlink>

                </DropDown>
                )}

                {page.text === "SERVICES" && (
                <DropDown>
                    <Rlink
                    to={`/products-consultation`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Products Consultation
                    </Rlink>
                    <Rlink
                    to={`/delivery-and-installation`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Delivery & Installation
                    </Rlink>
                    <Rlink
                    to={`/after-sales`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    After Sales Services
                    </Rlink>
                </DropDown>
                )}
            </DropDownBox>
            ))}
        </Box>
        </Toolbar>
    </Container>
    </AppBar>
);
}
export default NavBar;
