import { createTheme } from "@mui/material/styles";

const theme = createTheme({
palette: {
    mode: "dark",
    primary: {
    main: "#00A36C",
    dark: "#00A36C",
    white50: "#8A8E98",
    },
    secondary: {
    main: "#353C4E",
    dark: "#353C4E",
    login: "#161E32",
    },
    text: {
    primary: "#000000",
    secondary: "#000000",
    },
},
background: {
    default: "#0F172C",
    paper: "#000000",
},
text: {
    primary: "#000000",
    secondary: "#000000",
},
typography: {
    fontFamily: "Ubuntu",
},
components: {
    // Name of the component
    MuiMenuItem: {
    defaultProps: {
        backgroundColor: "white",
        disableRipple: true, // No more ripple, on the whole application 💣!
    },
    },
},
});

export default theme;
