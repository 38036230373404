import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ToastContainer } from "react-toastify";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";

const HeroSecDiv = styled.div`
height: 25vh;
background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url(/img/customize/ho.jpg);
background-position: center;
background-size: cover;

display: grid;
place-content: center;
color: white;
text-align: center;

h2 {
    font-size: 2.4rem;
    font-weight: 600;
    text-transform: capitalize;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
`;

const ShowItemsContainer = styled.div`
padding: 50px 0;

.p-container {
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: 30px;
    padding: 100px 0;

    p {
    color: #404040;
    font-size: 1.26rem;
    font-weight: 500;
    line-height: 160%;
    }
}
`;

const TitleBar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 50px;

p {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
    color: #606060;
    font-size: 1.2rem;
    background: #f0f0f0;
    width: 100%;
    padding: 10px 16px;
}
@media screen and (max-width: 1199px) {
    margin-bottom: 10px;
}
select {
    border: 1px solid #808080;
    padding: 10px 16px;
}

.search-cont {
    position: relative;
    width: fit-content;

    input {
    border: 1px solid #808080;
    padding: 10px 20px;
    }

    svg {
    position: absolute;
    top: 10px;
    right: 6px;
    }

    @media screen and (max-width: 1199px) {
    display: none;
    }
}
`;

export default function AfterSales() {

return (
    <>
    <NavBar />
    <HeroSec />
    <>
    <ShowItemsContainer>
        <Container maxWidth="lg">
            <TitleBar>
                <p>After Sales Services</p>
            </TitleBar>
            <div style={{ textAlign: "center" }}>
                <img
                    src="/img/services/maintenance.jpg" // Adjust the image path as necessary
                    alt="Maintenance"
                    style={{ maxWidth: "70%", height: "auto" }}
                />
            </div>
            <div className="p-container">
                <p>
                Our commitment to you extends far beyond the initial purchase. At INDOWATT, we take immense pride in our comprehensive after-sales service, particularly in diesel generator maintenance. Our dedicated team of skilled professionals stands ready to provide unparalleled support, ensuring the sustained performance and longevity of your generator investment.
                </p>

                <p>
                Our after-sales service encompasses a spectrum of maintenance solutions tailored to meet your specific needs. From routine check-ups and preventive maintenance to swift and expert repairs, we prioritize the seamless operation of your diesel generators. Count on us to deliver timely and reliable services that guarantee optimal performance, allowing you peace of mind and uninterrupted power supply. With INDOWATT, rest assured that your generator maintenance needs are in capable hands, empowering you to focus on your core activities without worrying about equipment reliability.
                </p>
            </div>
        </Container>
    </ShowItemsContainer>
    </>
    {/* CONTACT SECTION */}
    <Box sx={{ mt: 10 }}>
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a", textAlign: "center" }}
        fontSize={30}
        fontWeight={700}
        id="contact">
        GET IN TOUCH
        </Typography>

        <Box sx={{}}>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}>
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </Box>

    <div style={{ backgroundColor: "#EEEFF1" }}>
        <Container>
        <Footer />
        </Container>
    </div>

    <a
        href="https://wa.me/6281111127798"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
    </a>
    </>
);
}

function HeroSec() {
return (
    <HeroSecDiv>
    <div>
        <h2>After Sales Services</h2>
        <p>
        <span>Services</span>
        <span>{">"}</span>
        <span>After Sales Services</span>
        </p>
    </div>
    </HeroSecDiv>
);
}
