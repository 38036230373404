import { Box, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    footer: {
    backgroundColor: '#EEEFF1',
    padding: theme.spacing(1, 0),
    marginTop: theme.spacing(4)
    },
    logo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    },
    logoText: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
    
    },
    icon: {
    color: '#000000',
    marginLeft: theme.spacing(2),
    },
    copyright: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    },
}));

function Footer() {
    const classes = useStyles();

    return (
    <footer className={classes.footer}>
        
        <Grid container alignItems="center">
            <Grid item xs={12} sm={12} md={4}>
                <Box sx={{ alignItems:"left"}}>
                    <img src="./indowatt-only.png" alt="Indowatt Solutions" style={{ width: '50%', height: '50%', alignSelf: 'flex-start', marginTop: '1rem' }} />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
            <Box sx={{ alignItems:"left"}} >
                <Link href="#about" style={{ marginRight: 16, color:"#000000" }}>
                About Us
                </Link>
                <Link href="#products" style={{ marginRight: 16, color:"#000000" }}>
                Products
                </Link>
                <Link href="#services" style={{ marginRight: 16, color:"#000000" }}>
                Services
                </Link>
                <Link href="#contact" style={{ marginRight: 16, color:"#000000" }}>
                Contact Us
                </Link>
            </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
            <Box sx={{justifyContent: 'flex-end'}}>
                <IconButton href="https://www.linkedin.com/company/indowatt-solutions/" target="_blank" rel="noopener noreferrer" className={classes.icon}>
                <FaLinkedin size={24} />
                </IconButton>
                <IconButton href="https://www.facebook.com/profile.php?id=100092527821878" target="_blank" rel="noopener noreferrer" className={classes.icon}>
                <FaFacebook size={24} />
                </IconButton>
                <IconButton href="https://www.instagram.com/indowatt/" target="_blank" rel="noopener noreferrer" className={classes.icon}>
                <FaInstagram size={24} />
                </IconButton>
            </Box>
            </Grid>
        </Grid>
        <Box className={classes.copyRight}>
        <Typography variant="caption" style={{ color: '#000000' }}>
            COPYRIGHT © 2023 PT INDONESIA WATT SOLUTIONS. ALL RIGHTS RESERVED.
        </Typography>
        </Box>
        
    </footer>
    );
}

export default Footer;