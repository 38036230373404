import React from "react";
import ReactDOM from "react-dom/client";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Alternators from "./Alternator/Alternators";
import Customize from "./Customize/customize";
import jsonData from "./json/data.json";
import Consultation from "./Services/Consultation";

import Transportation from "./Services/Transportation";
import AfterSales from "./Services/AfterSales";

import Success from "./components/Success";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/alternators",
    element: <Alternators />,
  },
  {
    path: "/diesel-generator",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/generator/open-type",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/generator/silent-type",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/generator/controller",
    element: <Customize data={jsonData} />,
  },
  {
    path: "/products-consultation",
    element: <Consultation />,
  },
  {
    path: "/delivery-and-installation",
    element: <Transportation />,
  },
  {
    path: "/after-sales",
    element: <AfterSales />,
  },
  {
    path: "/success",
    element: <Success />,
  },  
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
