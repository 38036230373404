import React, { useState } from "react";
import {
Accordion,
AccordionSummary,
AccordionDetails,
Checkbox,
FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const CustomAccordion = ({
data,
mobile,
updateDtArr,
setDtArr,
setCurrentPage,
}) => {
const [init, setInit] = useState(true);
const [activeIndex, setActiveIndex] = useState(0);

const resetArr = [
    {
    title: "Open Type Diesel Generator",
    mobileTitle: "Open Type",
    content: [
        { label: "Cummins", checked: false },
        { label: "Perkins", checked: false },
    ],
    },
    {
    title: "Silent Type Diesel Generator",
    mobileTitle: "Silent Type",

    content: [
        { label: "Cummins", checked: false },
        { label: "Perkins", checked: false },
    ], // Add content if needed
    },
    {
        title: "Container Type Diesel Generator",
        mobileTitle: "Container Type",
    
        content: [
            { label: "Cummins", checked: false },
            { label: "Perkins", checked: false },
        ], // Add content if needed
        },
    {
    title: "Controller",
    mobileTitle: "Controller",

    content: [
        { label: "SmartGen", checked: false },
        { label: "Deep Sea", checked: false },
        { label: "DEIF", checked: false },
        { label: "ATS", checked: false },
        { label: "Synchronous Parallel", checked: false },
    ], // Add content if needed
    },
];
const [accordionData, setAccordionData] = useState(resetArr);

function convertObj(dtx) {
    let tx = dtx
    .split(" ")
    .map((itm) => itm.trim())
    .join("")
    .toLowerCase();
    return tx;
}
function areAllCheckedFalse(obj) {
    console.log(obj);
    return obj.content.every((item) => item.checked === false);
}

const handleChange = (index) => {
    setActiveIndex(activeIndex === index ? null : index);

    setInit(true);
    setDtArr([]);
    setAccordionData(resetArr);
    updateDtArr([], convertObj(accordionData[index].title));
    setCurrentPage(1);
};

const handleCheckboxChange = (parentIndex, childIndex) => {
    const updatedData = [...accordionData];
    updatedData[parentIndex].content[childIndex].checked =
    !updatedData[parentIndex].content[childIndex].checked;

    setActiveIndex(parentIndex);
    setAccordionData(updatedData);

    let objName = convertObj(updatedData[parentIndex].title);
    let subObjName = convertObj(
    updatedData[parentIndex].content[childIndex].label
    );

    setCurrentPage(1);

    if (updatedData[parentIndex].content[childIndex].checked) {
    console.log(objName);
    console.log(subObjName);

    updateDtArr(data[objName][subObjName], null, init);
    setInit(false);
    } else {
    const ifTrue = areAllCheckedFalse(updatedData[parentIndex]);
    if (ifTrue) {
        setInit(true);
    }

    updateDtArr(
        data[objName][subObjName],
        "remove",
        null,
        ifTrue,
        data[objName]
    );
    }
};

return (
    <>
    {accordionData.map((item, mainIndex) => {
        return (
        <>
            <Accordion
            sx={{ width: "300px" }}
            key={mainIndex}
            className="accordianStyle"
            expanded={activeIndex === mainIndex}
            onChange={() => handleChange(mainIndex)}>
            <AccordionSummary
                sx={{
                bgcolor: `${activeIndex === mainIndex ? `#00A36C` : ``}`,
                color: `${activeIndex === mainIndex ? `#fff` : ``}`,
                }}
                expandIcon={
                <ExpandMoreIcon
                    sx={{
                    color: `${activeIndex === mainIndex ? `#fff` : ``}`,
                    }}
                />
                }>
                {mobile ? item.mobileTitle : item.title}
            </AccordionSummary>
            <AccordionDetails>
                <div>
                {item.content.map((subtitle, subIndex) => (
                    <div key={subIndex}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            sx={{
                                
                                '&.Mui-checked': {
                                color: '#00A36C', // Change checked color if needed
                                },
                            }}
                            checked={subtitle.checked}
                            onChange={() =>
                            handleCheckboxChange(mainIndex, subIndex)
                            }
                        />
                        }
                        label={subtitle.label}
                    />
                    </div>
                ))}
                </div>
            </AccordionDetails>
            </Accordion>
        </>
        );
    })}
    </>
);
};

export default CustomAccordion;